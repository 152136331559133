import { Breadcrumbs, ContainerScreen, Loader } from 'components'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useGetCoverage,
  usePutCoverage,
} from 'domains/attendanceProfile/hooks/services/coverages'
import { FormProvider, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { createAndUpdateAttendanceProfileSchema } from 'domains/attendanceProfile/schemas'
import { CoverageInputRequest } from 'services/coverages'
import { AttendanceProfileForm } from 'domains/attendanceProfile/components/AttendanceProfileForm/AttendanceProfileForm'
import { parseCoverageInfoToEntityFormat } from 'domains/attendanceProfile/utilities/coverages'
import { useToast } from 'shared/hooks'
import { paths } from 'routes'

const UpdateAttendanceProfile = () => {
  const { addToast } = useToast()
  const { attendanceProfileId } = useParams()
  const navigate = useNavigate()

  const { coverage, isFetching, refetch } = useGetCoverage(
    attendanceProfileId || '',
    false,
  )

  const { mutateUpdateCoverage, statusUpdateCoverage } = usePutCoverage()

  const form = useForm<CoverageInputRequest>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(createAndUpdateAttendanceProfileSchema),
    defaultValues: async () => {
      const { data } = await refetch()

      return {
        name: data?.name,
        coverageType: data?.coverageType,
        accountIds: data?.accounts?.map((account) => account.id),
        accountTagIds: data?.accountTags?.map((accountTag) => accountTag.id),
        districtIds: data?.districts?.map((district) => district.id),
        cityIds: data?.cities?.map((city) => city.id),
        stateIds: data?.states?.map((state) => state.id),
        occurrenceCategories: data?.occurrenceCategories,
        serviceOrderTypes: data?.serviceOrderTypes,
        ...(data?.serviceTypes?.length && {
          serviceTypes: data.serviceTypes,
        }),
      }
    },
  })

  const onSubmit = (data: CoverageInputRequest) => {
    const { serviceTypes, ...rest } = data
    mutateUpdateCoverage(
      {
        id: coverage?.id || '',
        ...rest,
        ...(serviceTypes?.length && {
          serviceTypeIds: serviceTypes.map((serviceType) => serviceType.id),
        }),
      },
      {
        onSuccess: () => {
          addToast({ message: 'Perfil de atendimento editado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar perfil de atendimento. Tente novamente.',
            type: 'alert',
          })
        },
      },
    )
  }

  if (!coverage || isFetching || statusUpdateCoverage === 'pending')
    return <Loader isVisible />

  return (
    <>
      <ContainerScreen
        clickable
        divider={false}
        renderBreadcrumbs={
          <Breadcrumbs
            items={[
              { title: 'Home', href: '/' },
              {
                title: 'Perfis de atendimento',
                href: paths.configuration.attendanceProfile.list + '?page=1',
              },
              { title: coverage.name, href: '#' },
            ]}
          />
        }
      >
        <FormProvider {...form}>
          <AttendanceProfileForm
            onGoBack={() => navigate(-1)}
            onSubmit={onSubmit}
            attendanceProfile={{
              name: coverage.name,
              coverageType: coverage.coverageType,
              coverages: parseCoverageInfoToEntityFormat(coverage),
              serviceTypes: coverage.serviceTypes,
            }}
          />
        </FormProvider>
      </ContainerScreen>
    </>
  )
}

export default UpdateAttendanceProfile
